<template>
  <transition name="fade" mode="out-in">
    <div>
      <article v-show="load">
        <h1 v-html="data.title" v-if="data !== null"/>
        <component-picker
          v-if="data !== null"
          :data="data.body"
        />
        <tags 
          v-if="data !== null && data.tags"
          :tags="data.tags"
        />
      </article>
      <slot/>
    </div>
  </transition>
</template>

<script>
import ComponentPicker from '@/components/ComponentPicker.vue'

export default {
  name: 'Single',
  components: {
    ComponentPicker,
    Tags: () => import('@/components/Tags.vue')
  },
  metaInfo() {
    return {
      title: this.title
    }
  },
  data() {
    return {
      load: false,
      data: null,
    }
  },
  mounted() {
    this.$api
      .get(`${this.$route.params.type}?slug=${this.$route.params.slug}`)
      .then(response => {
        if (response.data.length > 0) {
          this.data = response.data[0]
          this.load = true
        } else {
          this.load = false
          this.data = null
          this.$router.push('/error')
        }
      })
      .catch(error => {
        console.log(error);
        this.$router.push({ name: 'Error', params: { error }})
      })
  },
  computed: {
    title() {
      return this.$options.filters.titleize(this.$route.params.slug)
    },
  }
}
</script>